@import url("https://fonts.googleapis.com/css?family=Roboto");
@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=Genos");
@import url("https://fonts.googleapis.com/css?family=Merriweather");

body {
  line-height: 140%;
  font-family: 'IBM Plex Mono', 'Perfect DOS VGA 437', monospace;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
  color: #d2d2d2;
  background-color: #161D1C;
  overflow-x: hidden;
}

p, .paragraph {
  font-weight: 400;
  font-size: 1 rem;
  font-family: 'IBM Plex Mono', 'Perfect DOS VGA 437', monospace;
  line-height: 140%;
}
p-lg, .paragraph-lg {
  font-size: 24px;
}
.menu-bar{
  background-color: #161D1C;
}

.lead-paragraph{
  font-size: 1.2rem;
  &.display{
    font-size: 1.8rem;
  }
}
.body2{
  &.display{
    font-size: 1.8rem;
  }
  font-size: 1.2rem;
}
.body1{
  &.display{
    font-size: 1.3rem;
  }
  font-size: 1rem;
}
.body
h1, h1, h2, h3, h4, h5, h6 {
  font-family: "Genos", sans-serif;
  text-transform: uppercase;
}

@media (max-width: 991px) {
  h1, .h1 {
    font-size: 65px;
  }
}
.caption-text{
  font-size: 0.9rem;
  font-weight: lighter;
}
@media (max-width: 991px) {
  h2, .h2 {
    font-size: 30px;
  }
}
h1, .h1{
  font-size: 28px;
  &.display{
    font-size: 40px;
  }
}
h2, .h2{
  font-size: 24px;
  &.display{
    font-size: 32px;
  }
}
h3, .h3 {
  font-size: 22px;
  &.display{
    font-size: 30px;
  }
}

h4, .h4 {
  font-size: 20px;
  &.display{
    font-size: 28px;
  }
}

h5, .h5 {
  font-size: 18px;
  &.display{
    font-size: 24px;
  }
}

h6, .h6 {
  font-size: 16px;
  &.display{
    font-size: 22px;
  }
}
.special{
  opacity: 0.45;
}
/* Color Style */
.primary{
  color: #F74D70;
}
.secondary{
  color: #5DE3F5;
}
/* Button style */
.btn {


  background-color: transparent;
  background-image: none;
  border: 1px solid;
  font-size: 22px;
  font-family: 'IBM Plex Mono', 'Perfect DOS VGA 437', monospace;
  text-transform: capitalize;
  padding: 12px 24px;
  border-radius: 0.5em;
  font-weight: 800;
  font-size: 1rem;
  font-weight: 600;
  position: relative;
  z-index: 1;
  transition: 0.2s ease;

  &.dark{
    border-color: #858585;
   
  }
  &.light{
    color: #fbfbfb;
    border-color: #fbfbfb;
  }

  &:active,&:focus,&:hover{
    background-color: transparent !important;
  }

  
}

.btn-sm {
  font-size: 15px;
  padding: 10px 30px;
}
.btn-xs {
  padding: 5px 10px;
  font-size: 15px;
}

.badge{
  padding: 0.5em 2em;
  border-radius: 5em;
  font-weight: 800;
  font-size: 1rem;
  font-family: 'IBM Plex Mono', 'Perfect DOS VGA 437', monospace;
}
a.badge, a.badge:hover{
  color: #1f1f1f;
}
.bg-danger, .bg-danger:hover {
  background-color: #FF799D !important;
  color: #6B172E !important;
}
a.bg-danger:hover{
  background-color: #FF799D !important;
  color: #6B172E !important;
}
::-moz-selection {
  background: #282B27;
  color: #fbfbfb;
}

::selection {
  background: #282B27;
  color: #fbfbfb;
}

/* preloader */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

ol,
ul {
  margin: 0px;
  padding-left: 1.4rem;
}

img {
  vertical-align: middle;
  border: 0;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a,
button,
select {
  cursor: pointer;
  transition: 0.2s ease;
}
a:focus,
button:focus,
select:focus {
  outline: 0;
}

a.text-dark:hover {
  color: #41228e !important;
}

a:hover {
  color: #41228e;
}

.slick-slide {
  outline: 0;
}

.section {
  padding-top: 45px;
  padding-bottom: 45px;
}
.section-title {
  margin-bottom: 80px;
  font-family: "Montserrat", serif;
}

.bg-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

/* overlay */
.overlay {
  position: relative;
}
.overlay::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #282B27;
  opacity: 0.5;
}

.outline-0 {
  outline: 0 !important;
}

.d-unset {
  display: unset !important;
}

.bg-primary {
  background-color: #161D1C !important;
}
.bg-primary.rounded-circle{
  background-color: #343A39 !important;
}
.bg-gray {
  background: #eaeaea !important;
}

.text-primary {
  color: #41228e !important;
}

.text-color {
  color: #4c4c4c;
}

.text-dark {
  color: #1f1f1f !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.zindex-1 {
  z-index: 1;
}

.rounded-lg {
  border-radius: 15px;
}

.overflow-hidden {
  overflow: hidden;
}

.font-primary{
  font-family: 'IBM Plex Mono', 'Perfect DOS VGA 437', monospace;
  font-weight: 600;
  opacity: 80% !important;
}

.shadow {
  box-shadow: 0px 18px 39.1px 6.9px rgba(224, 241, 255, 0.34) !important;
}

.bg-dark {
  background-color: #222222 !important;
}

/* icon */
.icon {
  font-size: 45px;
}

.icon-bg {
  height: 80px;
  width: 80px;
  line-height: 100px;
  text-align: center;
}

.icon-light {
  color: #c8d5ef;
}

/* /icon */
/* slick slider */
.slick-dots {
  text-align: center;
  padding-left: 0;
}
.slick-dots li {
  display: inline-block;
  margin: 2px;
}
.slick-dots li.slick-active button {
  background: #fff;
  width: 25px;
}
.slick-dots li button {
  height: 6px;
  width: 12px;
  background: rgba(255, 255, 255, 0.5);
  color: transparent;
  border-radius: 10px;
  overflow: hidden;
  transition: 0.2s ease;
  border: 0;
}

/* /slick slider */
/* form */
.form-control {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #c7c7c7;
  height: 60px;
}
.form-control:focus {
  border-color: #41228e;
  outline: 0;
  box-shadow: none !important;
}

textarea.form-control {
  height: 120px;
}

/* /form */
/* card */
.card {
  border: 0;
}
.card-header {
  border: 0;
}
.card-footer {
  border: 0;
}

/* /card */
.navigation {
  transition: 0.3s ease;
}
@media (max-width: 991px) {
  .navigation.nav-bg {
    padding: 15px 20px;
  }
}
@media (max-width: 991px) {
  .navigation {
    padding: 20px;
  }
}
.navbar-light .navbar-toggler{
  border-color: #3e3e3e !important;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar .nav-item .nav-link {
  font-family: "Genos", san-serif;
  text-transform: uppercase;
  padding: 8px 0;
  display: inline-block;
  font-size: 16px;
}
.navbar .nav-item{
  padding: 0 16px;
}
.navbar .nav-item.active{ 
  a{
    font-weight: bold;
    border-bottom: 2px solid #6e6e6e;
    padding: 8px 0 0 0;
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: #282B27;
}
.blurry-lines{
  color: #0b0c0b;
  text-shadow: 0px 0px 10px rgba(0,0,0,1);
  filter: blur(0.00px);
}
.hero-area {
  .networks{
    right: 0;
    bottom: 14rem;
  }
  .call-to-action{
    padding-top: 1rem;
  }
  padding: 8rem 0 0rem;
  position: relative;
  background-position: bottom center !important;
 // background-clip: content-box;         	
  background-repeat: no-repeat !important;    
  background-size: 100% 75% !important;
}

@media (max-width: 767px) {
  .hero-area {
    padding: 10rem 0 0rem;
    overflow: hidden;
    background-size: auto !important;
    
    .call-to-action{
      padding-top: 2rem;
    }
    .networks{
      right: -1.7rem;
      bottom: 7rem;
    }
  }
  
}
.hero-area h1 {
  position: relative;
  z-index: 2;
}

.layer {
  position: absolute;
  z-index: 1;
}

.layer-bg {
  position: absolute;
  bottom: 0;
  left: 0;
}

/* Main Cover */

.cover-text{
  h2{
    opacity: 90%;
    
  }
  &.font-secondary{
    opacity: 90%;
    font-weight: 500;
    font-size: 3.96rem;
    color: #f1f1f1;
    line-height: 96%;
  }  
  opacity: 95%;
}
.cover-paragraph{
  font-size: 1.7rem;
  font-weight: lighter;
}

/* skills */
.progress-wrapper {
  height: 130px;
  overflow: hidden;
}

.wave {
  position: absolute;
  width: 100%;
  height: 100%;
}
.wave::before, .wave::after {
  content: "";
  position: absolute;
  width: 800px;
  height: 800px;
  bottom: 0;
  left: 50%;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 45%;
  transform: translateX(-50%) rotate(0);
  -webkit-animation: rotate 6s linear infinite;
          animation: rotate 6s linear infinite;
  z-index: 10;
}
.wave::after {
  border-radius: 47%;
  background-color: rgba(255, 255, 255, 0.9);
  transform: translateX(-50%) rotate(0);
  -webkit-animation: rotate 10s linear -5s infinite;
          animation: rotate 10s linear -5s infinite;
  z-index: 20;
}

@-webkit-keyframes rotate {
  50% {
    transform: translateX(-50%) rotate(180deg);
  }
  100% {
    transform: translateX(-50%) rotate(360deg);
  }
}

@keyframes rotate {
  50% {
    transform: translateX(-50%) rotate(180deg);
  }
  100% {
    transform: translateX(-50%) rotate(360deg);
  }
}
/* /skills */
/* education */
.edu-bg-image {
  position: absolute;
  left: 0;
  top: -300px;
  z-index: -1;
}
.education{
  background-image: url(../../public/images/backgrounds/right-top-galaxy.jpg) !important;
  background-position: right top !important;   	 
  background-repeat: no-repeat !important;   
}

/* /education */
/* service */
.hover-bg-primary {
  transition: 0.3s ease;
}
.hover-bg-primary:hover {
  background: #f909e5;
}
.hover-bg-primary:hover * {
  color: rgb(254, 0, 0);
}

.active-bg-primary {
  transition: 0.3s ease;
  background: #41228e;
}
.active-bg-primary * {
  color: #282B27;
}


.hover-shadow:hover {
  box-shadow: 0px 18px 40px 8px rgba(22, 233, 36, 0.54) !important;
}

/* /service */

.tagline{
  color: #1e1e23;
  background-color: #fbfbfb;
  opacity: 0.88;
  font-weight: 500;
  padding: 0px 16px;
  border-radius: 32px;
  display: inline-block;
  font-size: 14px;

  &.case-study{
    background-color:#e3c7db;
  }

  &.challenge{
    background-color:#eedca6;
  }
  &.current{
    background-color:#a5e1e5;
  }

}
.odd{ 

  .tagline{
    &.case-study,&.challenge,&.current{
      color: #fff;
      font-weight: 800;
    }
    &.case-study{
      background-color:#6D10A6;
    }
  
    &.challenge{
      background-color:#9c7b17;
    }
    &.current{
      background-color:#0A7F88;
    }


  }
  
  ::-moz-selection {
    background:#fbfbfb;
    color: #282B27;
  }
  
  ::selection {
    background:#fbfbfb;
    color: #282B27;
  }
}
/* portfolio */
.hover-wrapper {
  overflow: hidden;
}
.hover-wrapper img {
  transition: 0.3s ease;
  transform: scale(1.1);
}
.hover-wrapper:hover img {
  transform: scale(1);
}
.hover-wrapper:hover .hover-overlay {
  opacity: 1;
  visibility: visible;
}

.hover-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
}

.hover-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  background-color: #110D14;
  padding: 1em;
}

/* /portfolio */
/* testimonial */
.testimonial-content strong {
  color: #2bfdff;
  font-weight: normal;
}

.testimonial-bg-shapes .container {
  position: relative;
  z-index: 1;
}
.testimonial-bg-shapes .bg-map {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.testimonial-bg-shapes .bg-shape-1 {
  position: absolute;
  left: 100px;
  top: -50px;
}
.testimonial-bg-shapes .bg-shape-2 {
  position: absolute;
  right: 150px;
  top: 50px;
}
.testimonial-bg-shapes .bg-shape-3 {
  position: absolute;
  right: 50px;
  top: 200px;
}
.testimonial-bg-shapes .bg-shape-4 {
  position: absolute;
  left: 100px;
  bottom: 200px;
}
.testimonial-bg-shapes .bg-shape-5 {
  position: absolute;
  right: 200px;
  bottom: -50px;
}

/* /testimonial */
/* footer */
.footer-section {
  padding-top: 4rem;  	 
  background-repeat: no-repeat !important;   
  background-color: #161D1C !important;
  color: #fbfbfb;
  p{
    opacity: 0.6;
  }
}

.section-on-footer {
  margin-bottom: -250px;
}

.shadow-down {
  position: relative;
}
.shadow-down::before {
  position: absolute;
  content: "";
  box-shadow: 0px 0px 80.75px 14.25px rgba(224, 241, 255, 0.34);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}

/* /footer */
.card-lg {
  flex-direction: row-reverse;
  align-items: center;
}
@media (max-width: 991px) {
  .card-lg {
    flex-direction: column;
  }
}

blockquote {
  display: inline-block;
  padding: 20px 25px;
  background: #eaeaea;
  border-left: 3px solid #41228e;
  font-style: italic;
  font-size: 22px;
  line-height: 1.7;
}

.content * {
  margin-bottom: 20px;
}
.content strong {
  font-family: "Montserrat", serif;
  font-size: 22px;
  font-weight: normal;
  color: #1f1f1f;
  display: inherit;
  line-height: 1.5;
}

.page-title-alt {
  padding: 350px 0 70px;
}

.border-thick {
  border: 10px solid;
}

.drag-lg-top {
  margin-top: -230px;
}
@media (max-width: 991px) {
  .drag-lg-top {
    margin-top: 0;
  }
}

.page-title-alt .container {
  position: relative;
  z-index: 1;
}
.page-title-alt .bg-shape-1 {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
.page-title-alt .bg-shape-2 {
  position: absolute;
  left: 70px;
  top: 100px;
}
.page-title-alt .bg-shape-3 {
  position: absolute;
  left: 30%;
  top: 50px;
  transform: rotate(180deg);
}
.page-title-alt .bg-shape-4 {
  position: absolute;
  left: 100px;
  bottom: 100px;
}
.page-title-alt .bg-shape-5 {
  position: absolute;
  left: 40%;
  bottom: -25px;
}
.page-title-alt .bg-shape-6 {
  position: absolute;
  bottom: 100px;
  right: -100px;
}

.page-title {
  padding: 9rem 0 0;
}
.page-title .container {
  position: relative;
  z-index: 1;
}
.page-title .bg-shape-1 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.page-title .bg-shape-2 {
  position: absolute;
  left: -20px;
  top: -10px;
}
.page-title .bg-shape-3 {
  position: absolute;
  left: 10%;
  top: 100px;
}
.page-title .bg-shape-4 {
  position: absolute;
  left: 50%;
  top: -20px;
}
.page-title .bg-shape-5 {
  position: absolute;
  left: 90px;
  bottom: -50px;
  transform: rotate(180deg);
}
.page-title .bg-shape-6 {
  position: absolute;
  right: 20%;
  bottom: -20px;
}
.page-title .bg-shape-7 {
  position: absolute;
  right: -220px;
  bottom: -100px;
}

.filter-controls li {
  cursor: pointer;
}
.filter-controls li.active {
  font-weight: bold;
}

.navbar-dark .navbar-brand img{
  width: 1em;
}

.underline{
  background-color: #B9C7B3;
}
.border-dark{
  border-color: #2f302f !important;
}
.related-cover{
  position: relative;
  
}
.title-mask{
 
  background-color: #131313;   
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 16px;
  opacity: 0.2;
  color: #fbfbfd;
  h3{
    position: absolute;
    top: 45%;
    left: 0;
    right: 0;
  }
  
}
.title-mask:hover{
  opacity: 0.7;
}

.hoverable{
  .title-mask{
    opacity: 0;
  }
  .title-mask:hover{
    opacity: 0.9;
  }
}
.navbar-brand{
  img{
    width: 6rem;
  }
}
.navbar{
  padding: 0 0.5rem 1rem 0rem;
}
/*
*   Home
*/
a, a:hover{
  color: #F8F7FA !important;
}
.text-white{
  color: #F8F7FA!important;
}
.text-light{
  color: #F8F7FA!important;
  opacity: 0.81;
}

.tagline{
  color: #1e1e23;
}

.layer{
  img{
      width: 1.8rem;
  }
}
.home-about{
  .badge{
      font-size: 18px;
  }

  .mt-2{
      margin-top: 0.5rem !important;
  }
  @media (min-width: 768px){
      .mt-6{
          margin-top: 22rem!important;
      }
  }
}
.scrolling-table{ 
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.odd{
  p,ul{
      color: #F8F7FA;
      opacity: 0.77;
  }
  .text-danger{
      color: #FF5F8A!important;
  }
  .text-success{
      color: #0B8F99 !important;
  }
  a{
      color: #F8F7FA !important;
  }
  a.btn-primary{
      color: #fbfbfb !important;
      border-color:  #fbfbfb;
  }
}



/*
*    Portfolio
*/
.portfolio-list{
  .hover-content{
      h4{
          color: #ffffff;
          line-height: 12px;
      }
      span{
          color: #ffffff;
      }
  }
}
.home{
  .portfolio-img{
      opacity: 0.65;
      border-radius: 16px;
  }
  .portfolio-img:hover{
      opacity: 
      0.88;
  }
}
.general-portfolio, .home-portfolio{
  .portfolio-img{
      border-radius: 16px;
  }
}
.portfolio-img, .about-img{
  width: 100%;
}
.home-about{
  .portfolio-img{
      border-radius: 16px;
  }
}
.about-img{
  border-radius: 16px;
}
.about-img-crop{
  width:100%;
  height:300px;
  object-fit:cover;
  border-radius: 16px;
}
.superglu{
  .overview{
      .portfolio-img{
          width: 60%;
      }
  }
}

.about-principal{
  position: absolute;
  top: 0;
}
.portfolio{
  &.dalig,&.pentalogix,&.superglu, &.drotini,&.viz,  &.pentacad,  &.thestralcorp, .picnic, .cyber{
      .odd{
          background-color: #252B2A;
      }
  }
 
  &.thestralcorp{
   
      .cover{
          /* Gradients/Liotta */
          background: linear-gradient(90deg, #F158C0 0%, #6E49F5 100%);
      }
  }
  &.picnic{
     
      .cover{
          /* Gradients/Liotta */
          background:  linear-gradient(90deg, #FFECD2 0%, #FCB69F 100%);
          color: #333;
      }
  }
  &.cyber{
     
      .cover{
          /* Gradients/Liotta */
          background:  linear-gradient(90deg, #1900BB 0%, #009343 100%);
      }
  }
  &.viz{
     
      .cover{
          /* Gradients/Liotta */
          background:  #2C2C2C;
      }
  }
  &.pentacad{
      .cover{
          /* Gradients/Liotta */
          background:  linear-gradient(90deg, #10074f 0%, #5d126e 100%);
      }
  }
}
/*
*   Skills
*/

.skills, .education{
  h4{
      line-height: 1.4em;
  }
}
.skill-badge{
  border-radius: 16px;
  border: 2px solid #acacac;
  padding: 32px;
  min-height: 21.4rem;
  
}
.column-gap {
/* Adjust the size of the gap as needed */
  margin-bottom: 32px; /* For spacing between rows */
}
.mokalu{
  .hover-content{
      background-color: #4D3A94;
  }
}
.kerubines{
  .hover-content{
      background-color: #489895;
  }
}
.epico{
  .hover-content{
      background-color: #21409A;
  }
}
.emergency{
  .hover-content{
      background-color: #2E3192;
  }
}
.thisbrand{
  .hover-content{
      background-color: #A4344E;
  }
}

/*Education*/
.education{
  .body2{
      line-height: 1.6;
  }
}

.avatar-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  line-height: 0;
  background-color: #B1C2D9;
  color: #FFFFFF;
}
.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.8333333333rem;
}

.avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  font-size: 1rem;
}
.rounded-circle {
  border-radius: 50% !important;
}
.video-wrapper {position: relative; padding-bottom: 56.25%; /* 16:9 */  padding-top: 25px;}
.video-wrapper iframe {position: absolute; top: 0; left: 0; width: 100%; height: 100%;}